import * as React from "react"
import {Link} from "gatsby"

const NotFoundPage = () => {
    return (
        <main>
            <h1 className={"text-3xl text-center block"}>Page Not Found</h1>
            <Link to={"/"}>Go back to homepage</Link>
        </main>
    )
}

export default NotFoundPage
